import AuthApi from '@/apis/AuthApi'
// import _ from 'lodash'
// import router from '@/router'
import { UserRoles } from '@/typings/UserTypings'
import moment from 'moment'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: false,
    userRole: '',
    token: '',
    requestLeft: 0
  },

  getters: {
    authenticated (state: any): boolean {
      return state.authenticated
    },

    token (state: any): boolean {
      return state.token
    },

    user (state: any) {
      return state.user
    },
    userRole (state: any): string {
      return state.userRole
    }
  },

  mutations: {
    SET_AUTHENTICATED (state: any, value: any) {
      state.authenticated = value
    },

    SET_USER (state: any, value: any) {
      state.user = value
    },

    SET_TOKEN (state: any, value: any) {
      if (value) {
        state.token = value
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + location.pathname
        document.cookie = 'token=' + value + '; expires=' + moment().add(1, 'years').toDate().toUTCString() + '; path=/'
      }
    },

    SET_USER_ROLE (state: any, value: string) {
      state.userRole = value
    },

    SET_USER_REQUEST_LEFT (state: any, value: any) {
      state.requestLeft = value < 0 ? 0 : value
    }
  },

  actions: {
    async signIn ({ dispatch, commit }, form) {
      const res = await AuthApi.login(form)
      if (res.data?.token) {
        commit('SET_TOKEN', res.data.token)
      }
      return dispatch('user')
    },

    async signOut({ commit }) {
      await AuthApi.logout()
        .then(() => {
          commit('SET_USER', null)
          commit('SET_AUTHENTICATED', false)
          commit('SET_USER_ROLE', null)
          commit('SET_TOKEN', '-')
        })
    },

    user({ commit }) {
      return AuthApi.user()
        .then((response) => {
          commit('SET_AUTHENTICATED', true)
          commit('SET_USER', response.data)
          commit('SET_TOKEN', response.data.token)
          commit('SET_USER_ROLE', UserRoles[response.data.user_role_id])
          if (response.data.request_left) commit('SET_USER_REQUEST_LEFT', response.data.request_left)
        })
        .catch(() => {
          commit('SET_AUTHENTICATED', false)
          commit('SET_USER', null)
          commit('SET_USER_ROLE', null)
        })
    },

    hasRole ({ state }, role: string) {
      return state.userRole === role
    }
  }
}
